module.exports = [{
      plugin: require('/zeit/54351995/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/54351995/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/zeit/54351995/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js"}},
    },{
      plugin: require('/zeit/54351995/node_modules/@primer/gatsby-theme-doctocat/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
